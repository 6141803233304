* {
    font-family: "Urbanist";
    /* scrollbar-width: none; */
    padding: 0px;
    margin: 0px;
}

/* .owl-carousel {
    position: relative;
    /* '&:hover .owl-nav': {
      opacity: 1
  }
} */

/* .owl-nav {
    width: 100%;
    position: absolute;
    display: flex;
    top: 30%;
    justify-content: space-between;
    transform: TranslateY(50%);
    transition: all 0.2s ease-in;
} */

/* .owl-prev {
    padding: 1rem;
    background: alpha("#000", 0.5);
    color: #fff; 
} */

/* .owl-next {
    padding: 1rem;
    background: alpha("#000", 0.5);
    color: #fff;
} */

a {
    text-decoration: none;
}
.my-footer a {
    color: inherit;
    text-decoration: none;
}

.my-footer a:hover {
    color: #347bf1;
}
